<template>
  <section class="dtc-main-section">
    <b-overlay :show="show" rounded="sm">
      <vue-tabs v-model="tabName" @tab-change="handleTabChange">
        <v-tab
          v-for="(item, i) in allUserRight"
          :title="item.text"
          :key="item + i"
        >
          <span v-show="showTab1">
            <iframe
              v-if="item.text == '籍屬分配會議'"
              id="iframe1"
              :src="`${domainObject.recruit}/#/apply5`"
              frameborder="0"
            ></iframe>
          </span>
          <iframe
            v-if="item.text == '招生系別與名額維護'"
            :src="`${domainObject.recruit}/#/apply6`"
            frameborder="0"
          ></iframe>
          <span v-show="showTab1">
            <iframe
              v-if="item.text == '三年內錄取名單'"
              id="iframe3"
              :src="`${domainObject.recruit}/#/apply27`"
              frameborder="0"
            ></iframe>
          </span>
          <span v-show="showTab1">
            <iframe
              v-if="item.text == '離島甄試保送生'"
              id="iframe4"
              :src="`${domainObject.recruit}/#/apply28`"
              frameborder="0"
            ></iframe>
          </span>
          <iframe
            v-if="item.text == '三年內錄取名單上傳'"
            :src="`${domainObject.recruit}/#/apply25`"
            frameborder="0"
          ></iframe>
          <iframe
            v-if="item.text == '離島甄試保送生上傳'"
            :src="`${domainObject.recruit}/#/apply26`"
            frameborder="0"
          ></iframe>
        </v-tab>
      </vue-tabs>
    </b-overlay>
  </section>
</template>

<script>
import { VueTabs, VTab } from "vue-nav-tabs";
import { store } from "@/store/global.js";
import "vue-nav-tabs/themes/vue-tabs.css";
import { domainObject } from "@/constant.js";

const urlPost = "applyform1/Post";
const urlPut = "applyform1/Put";
const urlGet = "applyform1/Get?Identifier=";
const zero = "T00:00:00";

const titles = [
  "籍屬分配會議",
  "招生系別與名額維護",
  "三年內錄取名單",
  "離島甄試保送生",
  "三年內錄取名單上傳",
  "離島甄試保送生上傳",
];

export default {
  name: "totalSpendTabsVue",
  data() {
    return {
      titles,
      showTab1: false,
      show: true,
      tabName: "",
      allUserRight: [
        { text: "籍屬分配會議", isActive: false, functionId: "10040" },
        { text: "招生系別與名額維護", isActive: false, functionId: "10041" },
        { text: "三年內錄取名單", isActive: false, functionId: "10042" },
        { text: "離島甄試保送生", isActive: false, functionId: "10043" },
        { text: "三年內錄取名單上傳", isActive: false, functionId: "10044" },
        { text: "離島甄試保送生上傳", isActive: false, functionId: "10045" },
      ],
      domainObject,
    };
  },
  components: {
    VueTabs,
    VTab,
  },
  methods: {
    handleTabChange(tabIndex, newTab, oldTab) {
      if (tabIndex == 1 || tabIndex == 4 || tabIndex == 5) {
        return;
      }
      let str =
        tabIndex == 0 ? "iframe1" : tabIndex == 2 ? "iframe3" : "iframe4";
      let iFrame = document.getElementById(str);
      const newUrl = iFrame.src.split("?")[0] + "?" + Date.now();
      iFrame.src = "";
      setTimeout(() => {
        iFrame.src = newUrl;
        this.showTab1 = false;
        this.show = true;
        setTimeout(() => {
          this.showTab1 = true;
          this.show = false;
        }, 500);
      }, 0);
    },
    setUserRight() {
      let arr = Boolean(sessionStorage.functionIds)
        ? JSON.parse(sessionStorage.getItem("functionIds"))
        : [];
      this.allUserRight = this.allUserRight.map((s) => {
        s.isActive = arr.find((k) => k == s.functionId) ? true : false;
        return s;
      });
      this.allUserRight = this.allUserRight.filter((s) => s.isActive);

      // arr = arr.map((s) => ({
      //   : s.Id,
      //   isActive: s.Name,
      // }));
      // let allData = (this.allData = JSON.parse(localStorage.dtxBuget));
      // allData = allData.find((s) => s.MenuName == "招生服務")
      //   ? allData.find((s) => s.MenuName == "招生服務")
      //   : {};
      // if (Boolean(allData.SubMenu)) {
      //   this.allUserRight = !allData.SubMenu.length
      //     ? []
      //     : allData.SubMenu.find((s) => s.MenuName == "招生前置作業");
      //   if (this.allUserRight.SubMenu.length) {
      //     this.allUserRight.SubMenu.forEach((s) => {
      //       this.userRights.push(s.MenuName);
      //     });
      //   }
      // }
    },
  },
  watch: {},
  async mounted() {
    setTimeout(() => {
      this.showTab1 = true;
      this.show = false;
    }, 0);

    this.setUserRight();
  },
  beforeMount() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.dtc-main-section {
  height: 100vh;
}
iframe {
  width: 100vw;
  height: clamp(60vh, 90vh, 100vh);
}
</style>
